import naterobinson from "./images/naterobinson.jpg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <img src={naterobinson} alt="logo" />
    </div>
  );
}

export default App;
